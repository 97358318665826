import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
    isRouteErrorResponse,
    Link,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useNavigate,
    useRouteError,
} from '@remix-run/react';
import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
//import toastify from 'react-toastify/dist/ReactToastify.css';
import styles from '@assets/css/index.css?url';
import 'react-toastify/dist/ReactToastify.css';
import { useToast } from '@hooks/useToast';
import { getUserToken } from '@server/login/login.server';
import { getToast } from '@server/toast.server';
import { combineHeaders } from '@server/utils/common';
import { SessionStoreProvider, useSessionStore } from '@store/provider/session.store.provider';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export async function loader({ request }: LoaderFunctionArgs) {
    const { toast, headers: toastHeaders } = await getToast(request);
    const codeinfo = await getUserToken(request);

    return json(
        {
            toast: toast,
            isCodeInfo: codeinfo ? true : false,
        },
        {
            headers: combineHeaders(toastHeaders),
        },
    );
}

export function Layout({ children }: { children: ReactNode }) {
    return (
        <html lang="ko">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />
                <Meta />
                <script src="./globalObject.js"></script>
                <Links />
            </head>
            <body>
                {children}

                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}

export default function App() {
    const data = useLoaderData<typeof loader>();
    useToast(data.toast);

    return (
        <SessionStoreProvider>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Header />
            <Outlet />
            <footer className="ft"></footer>
        </SessionStoreProvider>
    );
}

function Header() {
    const navigate = useNavigate();
    const { code, setCode } = useSessionStore((store) => store);

    return (
        <header>
            <div className="hw">
                <div className="hd">
                    <div className="logo"></div>
                    {code ? (
                        <div className="icon">
                            <span className="profile"></span>
                            <h4>
                                <Link
                                    to="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCode('');
                                        navigate('/', {
                                            replace: true,
                                        });
                                    }}
                                >
                                    Logout
                                </Link>
                            </h4>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </header>
    );
}

export function ErrorBoundary() {
    const error = useRouteError();

    return (
        <div className="error_con">
            <div className="error_in">
                <p className="error_tit">페이지를 찾을 수 없습니다.</p>
                <p className="error_copy">
                    요청하신 페이지의 주소가 잘못 입력되었거나,
                    <br />
                    주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
                </p>
            </div>
        </div>
    );
}
